<template>
  <div>
    <a-modal v-model="QRCode" title="二维码付款" width="800px" footer @cancel="cancel">
      <a-spin :spinning="loading">
        <div v-if="show">
          <h3 >付款须知：</h3>
          <p>
            &nbsp;&nbsp;&nbsp;平台为您提供微信支付的付款方式。付款成功后，如果在
            需求截止时间仍未选定合作工人，系统将原路退还您所支付的费用。如果您在接收到资料后，认为资料不符合质量要求，可在72小时内向平台发起人工介入。
          </p>
          <div style="width: 100%; text-align: center">
            <a-checkbox @change="onChange">
              <span>我已认真阅读付款须知</span>
            </a-checkbox>
          </div>
          <div style="width: 100%; text-align: center; margin-top: 10px">
            <a-button type="primary" style="display: inline-block" @click="FKclick" :disabled="!ok">
              <span v-if="showTime !== 0"> {{ showTime }}</span>
              <span v-else>立即付款</span>
            </a-button>
          </div>
        </div>
        <div v-if="!show" style="width: 100%; height: 100%">
          <p
            style="
              font-size: 24px;
              font-family: .AppleSystemUIFont;
              font-weight: 500;
              line-height: 28px;
              color: #434343;
            "
          >
            支付方式：
          </p>
          <a-row style="margin-bottom: 30px">
            <a-col :span="24" style="text-align: center">
              <div class="div_zf" @click="wx(1)">
                <img src="../../../../public/wx.png" alt="" class="div_zf_img" />
              </div>
            </a-col>
            <!-- <a-col :span="12" style="text-align: center">
              <div class="div_zf" @click="zfb(2)">
                <img src="../../../../public/zfb.png" alt="" class="div_zf_img" />
              </div>
            </a-col> -->
          </a-row>
          <div style="width: 100%; height: 250px; position: relative">
            <vueQr :text="codeUrl" :size="200" class="vueQr"></vueQr>
          </div>
          <div style="width: 100%; text-align: center">
            <h3>{{ title }}</h3>
          </div>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import { weChatPay, zfbChatPay, payNativeStatus, updateRead } from '@/api/materialShop'
// import { EnterpriseMaterialupdateStatus } from '@/api/msg'

import vueQr from 'vue-qr'
export default {
  name: 'Index',
  components: {
    vueQr
  },
  data () {
    return {
      // 需求id
      id: '',
      // 倒计时时间
      showTime: null,
      // 选中框
      checked: false,
      // 按钮显示
      ok: false,
      // 须知显示
      show: true,
      zfType: 1,
      title: '微信扫描上方二维码，完成支付后，可在“控制台-学习资料”中查看购买的资料。',
      outTradeNo: null,
      codeUrl: '',
      QRCode: false,
      loading: false,
      val: 1,
      row: {}
    }
  },
  watch: {
    // 监听数值变化
    QRCode (newName, oldName) {
      if (newName === false) {
        clearInterval(this.timer)
      }
    },
    immediate: true,
    deep: true
  },
  created () {
    this.val = 1
    this.zfType = 1
    clearInterval(this.timer)
  },
  methods: {
    refresh () {
      this.$parent.refresh()
      this.cancel()
    },
    onChange (e) {
      this.checked = e.target.checked
      if (this.showTime === 0 && this.checked) {
        this.ok = true
      } else {
        this.ok = false
      }
    },
    FKclick () {
      this.show = false
      updateRead({ id: this.id }).then((res) => {
        this.$message.success('已阅读付款须知')
      })
      this.loading = true
      this.val = 1
      this.zfType = 1
      this.codeUrl = ''
      this.title = '微信扫描上方二维码，完成支付'
      this.weChatPay(this.outTradeNo)
      this.setTimer()
    },
    wx (val) {
      if (!(val === this.zfType)) {
        this.zfType = 1
        this.loading = true
        this.title = '微信扫描上方二维码，完成支付。'
        this.weChatPay(this.outTradeNo)
      }
    },
    zfb (val) {
      if (!(val === this.zfType)) {
        this.zfType = 2
        this.loading = true
        this.title = '支付宝扫描上方二维码，完成支付。'
        this.zfbChatPay(this.outTradeNo)
      }
    },
    weChatPay (outTradeNo) {
      weChatPay({ outTradeNo })
        .then((res) => {
          this.loading = false
          this.codeUrl = res.data.codeUrl
        })
        .catch(() => {
          this.$message.error('微信二维码提取失败，请重试')
          this.cancel()
        })
    },
    zfbChatPay (outTradeNo) {
      zfbChatPay({ outTradeNo })
        .then((res) => {
          this.loading = false
          this.codeUrl = res.data
        })
        .catch(() => {
          this.$message.error('支付宝二维码提取失败，请重试')
          this.cancel()
        })
    },
    cancel () {
      clearInterval(this.timer)
      this.val = 1
      this.zfType = 1
      this.codeUrl = ''
      this.title = '微信扫描上方二维码，完成支付'
      this.QRCode = false
      this.loading = false
    },
    setTimer () {
      const that = this
      this.timer = setInterval(() => {
        payNativeStatus({ outTradeNo: this.outTradeNo }).then((res) => {
          if (res.data.status === '1') {
            this.QRCode = false
            this.$message.success('付款成功')
            that.refresh()
            // this.updataStuate()
          }
        })
      }, 1000)
    },
    // 修改状态
    // updataStuate () {
    //   EnterpriseMaterialupdateStatus({
    //     id: this.id,
    //     status: '1'
    //   }).then(res => {
    //     if (res.success) {
    //        this.$parent.refresh()
    //        this.cancel()
    //     }
    //   })
    // },
    // 传参（订单号）
    handleWeChatPay (id, outTradeNo) {
      this.$set(this, 'outTradeNo', outTradeNo)
      this.$set(this, 'id', id)
      this.QRCode = true
      this.show = true
      this.ok = false
      clearInterval(this.timer)
      this.showTime = 1
      this.timer = setInterval(() => {
        this.showTime--
        if (this.showTime === 0) {
          clearInterval(this.timer)
          if (this.checked === true) {
            this.ok = true
          }
        }
      }, 1000)
    }
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
